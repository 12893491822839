<template lang="pug">
div(:class='{ relative: $storyblok.isEditing.value }')
  div(class='flex flex-wrap items-center font-bold')
    span(class='mr-2') Tags:
    BaseButton(
      v-for='tag in tags'
      :key='tag'
      :to='buildUrl(tag)'
      size='XSMALL'
      color='GRAY'
      button-style='OUTLINE'
      class='text-sm normal-case px-4 py-3 mr-3 mb-1'
    ) {{ tag }}

  //- Shade for storyblok editor to prevent clicks
  div(v-if='$storyblok.isEditing.value' class='absolute inset-0 z-50')
</template>

<script setup lang="ts">
const { $storyblok } = useNuxtApp()

const tags = computed(() => {
  const tags = $storyblok.getTags()
  if (!tags) return
  // Filter out the 'blog' tag from the results
  return tags.filter((tag) => tag.toLowerCase() !== 'blog')
})

function buildUrl(tag: string) {
  const url = tag
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]/g, '-') // replace all non-alphanumeric characters with dashes
    .replace(/-+/g, '-') // replace consecutive dashes with a single dash
    .replace(/(^-|-$)/g, '') // remove dashes from start and end of string

  return `/blog/${url}`
}
</script>
